<template>
    <div class="login-wrap">
        <div class="ms-login">
            <div class="ms-title">聚铭优匠后台管理系统</div>
            <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
                <el-form-item prop="username">
                    <el-input v-model="param.username" placeholder="请输入用户名">
                        <template #prepend>
                            <el-button icon="el-icon-user"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input
                        type="password"
                        placeholder="请输入密码"
                        v-model="param.password"
                        @keyup.enter="submitForm()"
                    >
                        <template #prepend>
                            <el-button icon="el-icon-lock"></el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <div class="login-btn">
                    <el-button type="primary" @click="submitForm()">登录</el-button>
					
                </div>
				<!-- <div @click="reg()">
					<a href="#" style="color: #07283f;" class="login-tips" >注册账号</a>
				</div> -->
                <p class="login-tips">Tips :聚铭优匠 @啊博。</p>
				<!-- <p class="login-tips">Tips :备案号 粤ICP备2022067640号-1</p> -->
				<a class="login-tips" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022067640号-1</a>
            </el-form>
        </div>
		
    </div>
</template>

<script>
	import {login} from '../api/index.js'
export default {
    data() {
        return {
            param: {
                username: "",
                password: ""
            },
            rules: {
                username: [
                    { required: true, message: "请输入用户名", trigger: "blur" }
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" }
                ]
            }
        };
    },
    created() {
        this.$store.commit("clearTags");
		this.param.username = localStorage.getItem("ms_username", this.param.username);
    },
    methods: {
		reg(){
			console.log("aaaaccc");
			this.$router.push("/reg");
		},
        submitForm() {
			
			
			
			
			this.$refs.login.validate(valid => {
				if (valid) {
					
					login(this.param.username,this.param.password).then(res=>{
						console.log(res);
						res.username = this.param.username;
						console.log(res);
						if(res.result == 1 || res.result == 11){  //11是分身用户
							this.$message.success("登录成功");
							localStorage.setItem("ms_username", this.param.username);
							localStorage.setItem("userdata",JSON.stringify(res));
							if (res.result == 1) {
								this.$router.push("/dashboard");
								
							} else{
								this.$router.push("/GunDonPicEdit");
							}
						}else{
							this.$message.error("账号或密码错误");
						}
						
					}).catch(e=>{
						console.log(e);
					})
				
				} else {
					this.$message.error("请输入账号和密码");
					return false;
				}
			});
			
            
        }
    }
};
</script>

<style scoped>
.login-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(../assets/img/login-bg.jpg);
    background-size: 100%;
}
.ms-title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #fff;
    border-bottom: 1px solid #ddd;
}
.ms-login {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 350px;
    margin: -190px 0 0 -175px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.3);
    overflow: hidden;
}
.ms-content {
    padding: 30px 30px;
}
.login-btn {
    text-align: center;
}
.login-btn button {
    width: 100%;
    height: 36px;
    margin-bottom: 10px;
}
.login-tips {
    font-size: 12px;
    line-height: 30px;
    color: #fff;
}
</style>